import React, { useEffect, useState } from 'react';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { FormattedMessage } from 'react-intl';
import { TagList } from './list/TagList';
import { useLocation, useNavigate } from 'react-router-dom';
import { TagListToolbar } from './list/toolbar/TagListToolbar';
import { TagListDetails } from './details/TagListDetails';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { getFetching, getSelectedTag, getTags } from './Tags.selector';
import {
    fetchTags as fetchTagsThunk,
    fetchAssets as fetchAssetsThunk,
    fetchDrivers as fetchDriversThunk,
} from './tagsThunks';
import { getUserAccount } from '../../../configuration/login/loginSlice';
import { setUnsavedChanges, tagTableRowSelected } from './tagsSlice';

export const Tags = () => {
    const fetching = useAppSelector(getFetching);
    const accountId = useAppSelector(getUserAccount);
    const tags = useAppSelector(getTags);
    const selectedTag = useAppSelector(getSelectedTag);

    const dispatch = useAppDispatch();

    const setUnsavedTagChanges = (newValue: boolean) => dispatch(setUnsavedChanges(newValue));

    const [newRowId, setNewRowId] = useState<string | null>(null);
    const [showDiscardChangesDialog, setShowDiscardChangesDialog] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchTagsThunk({ accountId: accountId ?? null }));
    }, [dispatch, accountId]);

    useEffect(() => {
        dispatch(fetchAssetsThunk());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchDriversThunk());
    }, [dispatch]);

    useEffect(() => {
        const pathWithoutBaseUrl = location.pathname.slice(6);
        if (tags.map((tag) => tag.id).includes(pathWithoutBaseUrl)) {
            dispatch(tagTableRowSelected(pathWithoutBaseUrl));
        } else {
            dispatch(tagTableRowSelected(null));
        }
    }, [dispatch, tags, location]);

    const confirmDiscardChanges = () => {
        setUnsavedTagChanges(false);
        setShowDiscardChangesDialog(false);
        const pathname = newRowId ? `/tags/${newRowId}` : '/tags';
        navigate({ pathname });
    };

    const toggleDiscardChangesDialog = (opened: boolean, rowId: string | null) => {
        setShowDiscardChangesDialog(opened);
        setNewRowId(rowId);
    };

    const hideConfirmationDialog = () => {
        toggleDiscardChangesDialog(false, null);
    };

    return (
        <React.Fragment>
            <ConfirmationDialog
                show={showDiscardChangesDialog}
                title={<FormattedMessage id={'tags.unsavedChangesDialog.title'} />}
                content={<FormattedMessage id={'tags.unsavedChangesDialog.content'} />}
                onClickConfirm={confirmDiscardChanges}
                onClickCancel={hideConfirmationDialog}
                cancelButtonText={<FormattedMessage id={'tags.unsavedChangesDialog.button.cancel'} />}
                confirmButtonText={<FormattedMessage id={'tags.unsavedChangesDialog.button.confirm'} />}
                disableConfirm={false}
                useOverflow={false}
                bsSize={'sm'}
            />
            {selectedTag && (
                <ApplicationLayout.Sidebar className="right">
                    <TagListDetails toggleDiscardChangesDialog={toggleDiscardChangesDialog} />
                </ApplicationLayout.Sidebar>
            )}
            <ApplicationLayout.Body className={'responsive'}>
                {fetching ? (
                    <FormattedMessage id={'tags.spinner.loading'}>
                        {(loadingText) => <Spinner text={loadingText} />}
                    </FormattedMessage>
                ) : (
                    <>
                        <TagListToolbar />
                        <TagList toggleDiscardChangesDialog={toggleDiscardChangesDialog} />
                    </>
                )}
            </ApplicationLayout.Body>
        </React.Fragment>
    );
};
