import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tag, TagsWithAssignedAssets, TagsWithAssignedDrivers } from './types';
import { deleteTags, fetchAssets, fetchDrivers, fetchETag, fetchTags, updateTag } from './tagsThunks';
import { createTag } from './create/tagCreationThunk';

export interface TagState {
    fetching: boolean;
    tags: Tag[];
    selectedTag: Tag | null;
    selectedTagVersion: string | null;
    assignedAssets: TagsWithAssignedAssets;
    assignedAssetsLoaded: boolean;
    assignedDrivers: TagsWithAssignedDrivers;
    assignedDriversLoaded: boolean;
    tagUpdating: boolean;
    tagUpdateSuccessful: boolean;
    tagUpdateFailed: boolean;
    tagUpdateLastErrorCode: string | null;
    unsavedChanges: boolean;
    deletions: {
        inProgress: boolean;
        lastSuccess: string | null;
        lastFailure: string | null;
    };
}

export const initialState: TagState = {
    fetching: false,
    tags: [],
    selectedTag: null,
    selectedTagVersion: null,
    assignedAssets: {},
    assignedAssetsLoaded: false,
    assignedDrivers: {},
    assignedDriversLoaded: false,
    tagUpdating: false,
    tagUpdateSuccessful: false,
    tagUpdateFailed: false,
    tagUpdateLastErrorCode: null,
    unsavedChanges: false,
    deletions: {
        inProgress: false,
        lastSuccess: null,
        lastFailure: null,
    },
};

const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        setUnsavedChanges: (state, action: PayloadAction<boolean>) => {
            state.unsavedChanges = action.payload;
        },
        tagTableRowSelected: (state, action: PayloadAction<string | null>) => {
            const foundTag = state.tags.find((tag) => {
                return tag.id === action.payload;
            });
            state.selectedTag = foundTag === undefined ? null : foundTag;
        },
        deletionSuccess: (state, action: PayloadAction<string>) => {
            const tagSuccessName = state.tags.filter((tag) => action.payload === tag.id)[0]?.name ?? 'unknown';
            const currentlyAssignedAssets = { ...state.assignedAssets };
            delete currentlyAssignedAssets[action.payload];

            state.deletions.lastSuccess = tagSuccessName;
            state.deletions.lastFailure = null;
            state.tags = state.tags.filter((tag) => tag.id !== action.payload);
            state.assignedAssets = currentlyAssignedAssets;
        },
        deletionFailure: (state, action: PayloadAction<string>) => {
            const tagFailedName = state.tags.filter((tag) => action.payload === tag.id)[0]?.name ?? 'unknown';
            state.deletions.lastSuccess = null;
            state.deletions.lastFailure = tagFailedName;
        },
        updateSuccess: (state, action: PayloadAction<{ tagId: string; name: string }>) => {
            state.tags = state.tags.map((tag) => {
                return tag.id === action.payload.tagId ? { ...tag, name: action.payload.name } : tag;
            });
            state.tagUpdateSuccessful = true;
        },
        updateFailure: (state, action: PayloadAction<{ errorCode: string | null }>) => {
            state.tagUpdateLastErrorCode = action.payload.errorCode;
            state.tagUpdateFailed = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTags.pending, (state) => {
            state.fetching = true;
        });
        builder.addCase(fetchTags.fulfilled, (state, action) => {
            state.fetching = false;
            state.tags = action.payload.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        });
        builder.addCase(fetchTags.rejected, (state) => {
            state.fetching = false;
        });

        builder.addCase(fetchETag.pending, () => {});
        builder.addCase(fetchETag.fulfilled, (state, action) => {
            state.selectedTagVersion = action.payload;
        });
        builder.addCase(fetchETag.rejected, (state) => {
            state.selectedTagVersion = null;
        });

        builder.addCase(fetchAssets.pending, () => {});
        builder.addCase(fetchAssets.fulfilled, (state, action) => {
            let assignedAssets: TagsWithAssignedAssets = {};
            action.payload.forEach((asset) => {
                asset.tagIds.forEach((tagId) => {
                    assignedAssets = {
                        ...assignedAssets,
                        [tagId]: assignedAssets[tagId]?.concat(asset) ?? [asset],
                    };
                });
            });

            state.assignedAssetsLoaded = true;
            state.assignedAssets = assignedAssets;
        });
        builder.addCase(fetchAssets.rejected, (state) => {
            state.assignedAssets = {};
        });

        builder.addCase(fetchDrivers.pending, () => {});
        builder.addCase(fetchDrivers.fulfilled, (state, action) => {
            let assignedDrivers: TagsWithAssignedDrivers = {};
            action.payload.forEach((driver) => {
                driver.tagIds.forEach((tagId) => {
                    assignedDrivers = {
                        ...assignedDrivers,
                        [tagId]: assignedDrivers[tagId]?.concat(driver) ?? [driver],
                    };
                });
            });

            state.assignedDriversLoaded = true;
            state.assignedDrivers = assignedDrivers;
        });
        builder.addCase(fetchDrivers.rejected, (state) => {
            state.assignedDriversLoaded = false;
            state.assignedDrivers = {};
        });

        builder.addCase(deleteTags.pending, (state) => {
            state.deletions.inProgress = true;
            state.deletions.lastSuccess = null;
            state.deletions.lastFailure = null;
        });
        builder.addCase(deleteTags.fulfilled, (state) => {
            state.deletions.inProgress = false;
            state.deletions.lastSuccess = null;
            state.deletions.lastFailure = null;
        });
        builder.addCase(deleteTags.rejected, () => {});

        builder.addCase(updateTag.pending, (state) => {
            state.tagUpdating = true;
        });
        builder.addCase(updateTag.fulfilled, (state) => {
            state.tagUpdating = false;
            state.tagUpdateFailed = false;
            state.tagUpdateLastErrorCode = null;
            state.tagUpdateSuccessful = false;
        });
        builder.addCase(updateTag.rejected, () => {});

        builder.addCase(createTag.fulfilled, (state, action) => {
            state.tags = [...state.tags, action.payload].sort((a, b) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            );
        });
    },
});

export const {
    setUnsavedChanges,
    tagTableRowSelected,
    deletionSuccess,
    deletionFailure,
    updateSuccess,
    updateFailure,
} = tagsSlice.actions;

export default tagsSlice.reducer;
