import packageJson from '../package.json';

export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
        tagService: string | undefined;
        assetService: string | undefined;
        driverService: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        mockTenant: string | undefined;
        mockFleetId: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    serviceVersion: string;
    serviceEnvironment: string;
    logoutUri: string | undefined;
    postLogoutRedirectUri: string | undefined;
    sentryToken: string;
    links: {
        assetAdministration: string | undefined;
        driverAdministration: string | undefined;
    };
}

const asBool = (value: string | undefined): boolean => value === 'true';

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        tagService: import.meta.env.VITE_TAG_SERVICE,
        assetService: import.meta.env.VITE_ASSET_SERVICE,
        driverService: import.meta.env.VITE_DRIVER_SERVICE,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '951f3e30-5e61-4d6a-8bb5-4ac7ab66021e',
        oauthScope: [
            'openid',
            'profile',
            'email',
            'tags.read',
            'tags.write',
            'drivers.read',
            'asset-administration.read',
        ],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        mockTenant: 'rio-eu.test',
        mockFleetId: import.meta.env.VITE_LOGIN_MOCK_FLEET,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    postLogoutRedirectUri: import.meta.env.VITE_POST_LOGOUT_REDIRECT_URI,
    sentryToken: 'https://a4891d4637684f74a18ff5ecaa45d8e3@o117480.ingest.sentry.io/5240304',
    links: {
        assetAdministration: import.meta.env.VITE_ASSET_ADMINISTRATION,
        driverAdministration: import.meta.env.VITE_DRIVER_ADMINISTRATION,
    },
};
