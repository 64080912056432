import { configureStore, combineReducers } from '@reduxjs/toolkit';
import langReducer from '../lang/langSlice';
import appReducer from '../../features/app/appSlice';
import loginReducer from '../login/loginSlice';
import tokenHandlingReducer from '../tokenHandling/tokenHandlingSlice';
import tagReducer from '../../features/app/tags/tagsSlice';
import tagCreationDialogReducer from '../../features/app/tags/create/tagCreationDialogSlice';
import tagListToolbarReducer from '../../features/app/tags/list/toolbar/tagListToolbarSlice';

export const rootReducer = combineReducers({
    lang: langReducer,
    app: appReducer,
    login: loginReducer,
    tokenHandling: tokenHandlingReducer,
    tags: tagReducer,
    tagCreationDialog: tagCreationDialogReducer,
    tagsTableToolbar: tagListToolbarReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        reducer: rootReducer,
        preloadedState,
    });

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
