import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../configuration/setup/store';
import { createTag } from './tagCreationThunk';

export interface TagCreationDialogState {
    isTagCreationDialogOpen: boolean;
    tagCreating: boolean;
    tagCreationSuccessful: boolean;
    tagCreationFailed: boolean;
    lastErrorCode: string | null;
}

export const initialState: TagCreationDialogState = {
    isTagCreationDialogOpen: false,
    tagCreating: false,
    tagCreationSuccessful: false,
    tagCreationFailed: false,
    lastErrorCode: null,
};

const tagCreationDialogSlice = createSlice({
    name: 'tagCreationDialog',
    initialState,
    reducers: {
        openTagCreationDialog: (state) => {
            state.isTagCreationDialogOpen = true;
        },
        closeTagCreationDialog: (state) => {
            state.isTagCreationDialogOpen = false;
        },
        tagCreationFinished: (state) => {
            state.tagCreating = false;
            state.tagCreationSuccessful = false;
            state.tagCreationFailed = false;
            state.lastErrorCode = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createTag.pending, (state) => {
            state.tagCreating = true;
        });

        builder.addCase(createTag.fulfilled, (state) => {
            state.tagCreating = false;
            state.tagCreationSuccessful = true;
            state.isTagCreationDialogOpen = false;
        });

        builder.addCase(createTag.rejected, (state, { payload }) => {
            state.tagCreating = false;
            state.tagCreationFailed = true;
            if (hasErrorCode(payload)) {
                state.lastErrorCode = payload.errorCode;
            } else {
                state.lastErrorCode = null;
            }
        });
    },
});

export default tagCreationDialogSlice.reducer;

// Actions
export const { openTagCreationDialog, closeTagCreationDialog, tagCreationFinished } = tagCreationDialogSlice.actions;

// Selectors
export const getShowDialog = (state: RootState): boolean => {
    return state.tagCreationDialog.isTagCreationDialogOpen;
};

export const getTagCreating = (state: RootState): boolean => {
    return state.tagCreationDialog.tagCreating;
};

export const getTagCreationSuccessful = (state: RootState): boolean => {
    return state.tagCreationDialog.tagCreationSuccessful;
};

export const getTagCreationFailed = (state: RootState): boolean => {
    return state.tagCreationDialog.tagCreationFailed;
};

export const getLastErrorCode = (state: RootState): string | null => {
    return state.tagCreationDialog.lastErrorCode;
};

// utils
const hasErrorCode = (payload: unknown): payload is { errorCode: string } => {
    return (payload as { errorCode: string }).errorCode !== undefined;
};
