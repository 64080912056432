import { Provider } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';

import { main } from './configuration';
import { store } from './configuration/setup/store';

import { config } from './config';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import DefaultRedirect from './features/app/DefaultRoute';
import { createRoot } from 'react-dom/client';
import handleLoginRedirect from './configuration/login/redirect';
import App from './features/app/App';
import { GoogleTagListener } from './GtmTagListener';

const renderApplication = () => {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <HashRouter>
                    <Routes>
                        <Route path="*" element={<DefaultRedirect />} />
                        <Route path="/tags" element={<App />} />
                        <Route path="/tags/:id" element={<App />} />
                    </Routes>
                    <GoogleTagListener />
                </HashRouter>
            </Provider>
        </ErrorBoundary>
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
