import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../configuration/setup/store';

export interface AppState {
    sessionExpiredAcknowledged: boolean;
}

export const initialState: AppState = {
    sessionExpiredAcknowledged: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: (state) => {
            state.sessionExpiredAcknowledged = true;
        },
    },
});

export const { hideSessionExpiredDialog } = appSlice.actions;

export default appSlice.reducer;

export const getSessionExpiredAcknowledged = (state: RootState) => state.app.sessionExpiredAcknowledged;
