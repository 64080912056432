import React from 'react';
import Notification from '@rio-cloud/rio-uikit/Notification';

export function nonVanishingErrorNotification(
    message: string | React.JSX.Element | React.JSX.Element[],
    errorCode: string,
    dataAttribute?: string
) {
    Notification.error(
        <span data-cy={dataAttribute}>
            {message}
            <hr />
            {'Error Code: ' + errorCode}
            <span className={'notification-close'}>
                <span className="rioglyph rioglyph-remove" />
            </span>
        </span>,
        '',
        1000 * 60 * 15,
        () => {}
    );
}

export function closableSuccessNotification(
    message: string | React.JSX.Element | React.JSX.Element[],
    dataAttribute?: string
) {
    Notification.success(
        <span data-cy={dataAttribute}>
            {message}
            <span className={'notification-close'}>
                <span className="rioglyph rioglyph-remove" />
            </span>
        </span>,
        '',
        1000 * 5,
        () => {}
    );
}
