import { useEffect, useRef, useState } from 'react';

// from https://usehooks.com/useLocalStorage/
export const useLocalStorage = <T>(key: string, initialValue: T): [T, (s: T) => void] => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that persists the new value to localStorage.
    const setValue = (value: T) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error(error);
        }
    };

    return [storedValue, setValue];
};

// see https://stackoverflow.com/questions/55075604/react-hooks-useeffect-only-on-update
const useIsMounted = () => {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return isMounted;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useUpdateEffect = (effect: () => void | (() => void), dependencies?: any[]) => {
    const isMounted = useIsMounted();
    const dependenciesNeverChanged = useRef(true);

    useEffect(() => {
        let effectCleanupFunc = () => {};

        if (dependenciesNeverChanged.current) {
            dependenciesNeverChanged.current = false;
        } else {
            effectCleanupFunc = effect() || effectCleanupFunc;
        }
        return () => {
            effectCleanupFunc();
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (!isMounted.current) {
                dependenciesNeverChanged.current = true;
            }
        };
        // eslint-disable-next-line
    }, dependencies);
};
