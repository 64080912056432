import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import classNames from 'classnames';
import { reportErrorToSentry } from '../../../../../configuration/setup/sentry';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { getSelectedTag, getSelectedTagVersion, getTagUpdating } from '../../Tags.selector';
import { Tag } from '../../types';
import { deleteTags, updateTag as updateTagThunk, fetchETag as fetchETagThunk } from '../../tagsThunks';

export interface TagListDetailsFooterProps {
    newTagName: string;
}

export const TagListDetailsFooter = (props: TagListDetailsFooterProps) => {
    const { newTagName } = props;

    const dispatch = useAppDispatch();

    const deleteTag = (tagId: string) => dispatch(deleteTags({ tagIds: [tagId] }));
    const updateTag = (updatedTag: Tag, tagVersion: string) =>
        dispatch(updateTagThunk({ tag: updatedTag, tagVersion }));

    const selectedTag = useAppSelector(getSelectedTag);
    const selectedTagVersion = useAppSelector(getSelectedTagVersion);
    const tagUpdating = useAppSelector(getTagUpdating);

    const [showDeleteSingleTagDialog, setShowDeleteSingleTagDialog] = useState<boolean>(false);

    useEffect(() => {
        const fetchETag = (tagId: string) => dispatch(fetchETagThunk({ tagId }));
        if (selectedTag) {
            fetchETag(selectedTag.id);
        }
    }, [dispatch, selectedTag]);

    const deleteClick = () => {
        setShowDeleteSingleTagDialog(true);
    };

    const deleteButton = (
        <button
            className="btn btn-danger btn-link"
            type="button"
            disabled={false}
            onClick={deleteClick}
            data-cy="detail-view-delete-button"
        >
            <span className="rioglyph rioglyph-trash text-color-danger margin-right-5" />
            <span>
                <FormattedMessage id={'tags.tagDetails.deleteTagButton'} />
            </span>
        </button>
    );

    const saveButtonDisabled = () =>
        newTagName.trim().length === 0 ||
        selectedTag?.name === newTagName ||
        tagUpdating ||
        selectedTagVersion === null;

    const submitButtonClick = () => {
        if (selectedTag !== null && selectedTagVersion !== null) {
            updateTag({ ...selectedTag, name: newTagName }, selectedTagVersion);
        } else {
            reportErrorToSentry(new Error('Could not update tag because the selected Tag or version is undefined'));
        }
    };

    const submitButtonClassNames = classNames('btn', 'btn-primary', tagUpdating && 'btn-loading');
    const submitButton = (
        <button
            className={submitButtonClassNames}
            type="button"
            disabled={saveButtonDisabled()}
            onClick={submitButtonClick}
            data-cy="detail-view-submit-button"
        >
            <FormattedMessage id={'tags.tagDetails.submit'} />
        </button>
    );

    const onConfirmDelete = () => {
        if (selectedTag) {
            setShowDeleteSingleTagDialog(false);
            deleteTag(selectedTag.id);
        }
    };

    const onCancelDelete = () => {
        setShowDeleteSingleTagDialog(false);
    };

    const tagName = selectedTag?.name ?? '';

    return (
        <React.Fragment>
            <div className="btn-toolbar pull-left">{deleteButton}</div>
            <div className="btn-toolbar pull-right">{submitButton}</div>
            <div data-cy="tags-delete-single-tag-confirmation-dialog">
                <ConfirmationDialog
                    show={showDeleteSingleTagDialog}
                    title={<FormattedMessage id={'tags.tagDetails.deleteConfirmationDialog.title'} />}
                    bsSize={'sm'}
                    content={
                        <FormattedMessage
                            id={'tags.tagDetails.deleteConfirmationDialog.content'}
                            values={{ tagName }}
                        />
                    }
                    onClickConfirm={onConfirmDelete}
                    onClickCancel={onCancelDelete}
                    cancelButtonText={
                        <FormattedMessage id={'tags.tagDetails.deleteConfirmationDialog.button.cancel'} />
                    }
                    confirmButtonText={
                        <FormattedMessage id={'tags.tagDetails.deleteConfirmationDialog.button.confirm'} />
                    }
                    useOverflow={true}
                    disableConfirm={false}
                />
            </div>
        </React.Fragment>
    );
};
