import React, { useEffect, useState } from 'react';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { FormInputField } from './FormInputField';
import { useNavigate } from 'react-router-dom';
import { TagListDetailsFooter } from './sidebarfooter/TagListDetailsFooter';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { getSelectedTag, getSelectedTagVersion, getUnsavedChanges } from '../Tags.selector';
import { setUnsavedChanges } from '../tagsSlice';

export interface TagListDetailsProps {
    toggleDiscardChangesDialog: (opened: boolean, rowId: string | null) => void;
}

const SIDEBAR_BREAKPOINT = 1440;
export const ERROR_CODE_NAME_ALREADY_IN_USE = 'T06';
export const ERROR_CODE_GENERIC = 'TXX';

export const TagListDetails = (props: TagListDetailsProps) => {
    const { toggleDiscardChangesDialog } = props;

    const selectedTag = useAppSelector(getSelectedTag);
    const unsavedChanges = useAppSelector(getUnsavedChanges);
    const selectedTagVersion = useAppSelector(getSelectedTagVersion);

    const dispatch = useAppDispatch();

    const initialTagName = selectedTag?.name ?? '';
    const [currentTagName, setCurrentTagName] = useState<string>(initialTagName);
    const navigate = useNavigate();

    useEffect(() => {
        const tagName = selectedTag?.name ?? '';
        setCurrentTagName(tagName);
    }, [selectedTag]);

    const onClose = () => {
        if (unsavedChanges) {
            toggleDiscardChangesDialog(true, null);
        } else {
            navigate({ pathname: '/tags' });
        }
    };

    const onTagNameValueChange = (e: React.FormEvent<HTMLInputElement>) => {
        const newName = e.currentTarget.value;
        dispatch(setUnsavedChanges(newName !== initialTagName));
        setCurrentTagName(newName);
    };

    const sideBarContent = () => (
        <form data-cy="detail-view-form">
            <FormInputField
                translationId={'tags.tagDetails.name'}
                currentValue={currentTagName}
                disabled={selectedTagVersion === null}
                onValueChange={onTagNameValueChange}
                dataAttribute="detail-view-form-name"
                withCopyButton={true}
                maxLength={100}
            />
        </form>
    );

    return (
        <React.Fragment>
            <Sidebar
                title={currentTagName}
                closed={false}
                position={'right'}
                onClose={onClose}
                resizable={true}
                minWidth={300}
                width={400}
                footer={<TagListDetailsFooter newTagName={currentTagName} />}
                switchModeBreakpoint={SIDEBAR_BREAKPOINT}
                titleClassName={'padding-left-10'}
                bodyClassName={'padding-left-20 padding-right-20'}
            >
                {sideBarContent()}
            </Sidebar>
        </React.Fragment>
    );
};
