import join from 'lodash/fp/join';
import { mapUserProfile } from './userProfile';
import { config } from '../../config';
import { InMemoryWebStorage, User, UserManager, UserProfile, WebStorageStateStore } from 'oidc-client-ts';
import { jwtDecode } from 'jwt-decode';
import { OAuthConfig, SessionRenewedResult, Tokens } from '../types';

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.signinSilent().catch((error) => {
        if (error.message === 'login_required') {
            oauthConfig.onSessionExpired();
        } else {
            setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
        }
    });
};

export const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
    accessToken: result.access_token,
    idToken: result.id_token ?? null,
    locale: result.profile?.locale ?? 'en-GB',
    profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
    const redirectUri = config.login.redirectUri;
    const silentRedirectUri = config.login.silentRedirectUri;

    const settings = {
        authority: `${config.login.authority}`,
        client_id: `${config.login.clientId}`,
        loadUserInfo: false,
        redirect_uri: `${redirectUri}`,
        response_type: 'code',
        scope: join(' ', config.login.oauthScope),
        silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
        includeIdTokenInSilentRenew: false,
        automaticSilentRenew: true,
        userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    };

    return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.events.addUserLoaded((user) => {
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
    });

    userManager.events.addUserUnloaded(() => {
        oauthConfig.onSessionExpired();
    });

    userManager.events.addSilentRenewError(() => {
        retrySigninSilent(oauthConfig, userManager);
    });

    userManager.events.addUserSignedOut(() => {
        oauthConfig.onSessionExpired();
    });

    return userManager;
};

export const configureMockUserManager = (
    { onSessionRenewed }: OAuthConfig,
    { accessToken, idToken }: Tokens
): UserManager => {
    if (import.meta.env.MODE !== 'test') {
        console.warn(`[feature/login/oidc-session] Using mocked authorization due to config setting`);
    }

    const signinSilent = async () => {
        const jwtPayload = idToken && jwtDecode(idToken);

        const accountId = jwtPayload && (jwtPayload as any).account;
        const profile: UserProfile = {
            iss: (jwtPayload && jwtPayload.iss) || 'Issuer Identifier',
            sub: (jwtPayload && jwtPayload.sub) || 'prod-rio-users:mock-user-id',
            aud: (jwtPayload && `${jwtPayload.aud}`) || 'Audience(s): client_id',
            exp: (jwtPayload && jwtPayload.exp) || 10,
            iat: (jwtPayload && jwtPayload.iat) || 5,
            account: accountId ? accountId : 'mockaccount',
            azp: 'test-client',
            email: 'test@example.com',
            family_name: 'Client',
            given_name: 'Test',
            name: 'Test Client',
            username: 'preferred_username',
            locale: config.login.mockLocale,
            tenant: config.login.mockTenant,
            rawValue: idToken || 'fake-id-token',
        };

        const userSettings = {
            access_token: accessToken || 'valid-mocked-oauth-bogus-token',
            profile: profile,
            id_token: idToken || 'fake-id-token',
            session_state: 'session_state',
            refresh_token: 'refresh_token',
            token_type: 'token_type',
            scope: 'scope',
            expires_at: 100000,
            state: 'state',
        };

        const user = new User(userSettings);

        onSessionRenewed(adaptPublishedInfo(user));
        return Promise.resolve();
    };

    const clearStaleState = () => {
        console.info('[feature/login/oidc-session] Stale state cleared');
        return Promise.resolve();
    };

    return { signinSilent, clearStaleState } as unknown as UserManager;
};
