import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import TableViewToggles, { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { RootState } from '../../../../../configuration/setup/store';

export interface TagListToolbarState {
    tableViewType: string;
    isTagCreationDialogOpen: boolean;
}

export const initialState: TagListToolbarState = {
    tableViewType: TableViewToggles.VIEW_TYPE_TABLE,
    isTagCreationDialogOpen: false,
};

const tagListToolbarSlice = createSlice({
    name: 'tagListToolbar',
    initialState,
    reducers: {
        tagsTableViewTypeChanged: (state, action: PayloadAction<TableViewTogglesViewType>) => {
            state.tableViewType = action.payload;
        },
    },
});

export const { tagsTableViewTypeChanged } = tagListToolbarSlice.actions;

export default tagListToolbarSlice.reducer;

export function getTableViewType(state: RootState): string {
    return state.tagsTableToolbar.tableViewType;
}
