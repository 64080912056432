import { createSlice } from '@reduxjs/toolkit';
import { accessToken } from './accessToken';
import { RootState } from '../setup/store';
import { AccessToken } from '../types';

const initialState: TokenHandlingState = {
    accessToken: accessToken.getAccessToken(),
    idToken: null,
};

export interface TokenHandlingState {
    accessToken: AccessToken;
    idToken: string | null;
}

const tokenHandlingSlice = createSlice({
    name: 'tokenHandling',
    initialState,
    reducers: {
        accessTokenStored: (state, action) => {
            state.accessToken = action.payload;
        },
        idTokenStored: (state, action) => {
            state.idToken = action.payload;
        },
    },
});

export const { accessTokenStored, idTokenStored } = tokenHandlingSlice.actions;
export default tokenHandlingSlice.reducer;

export const getAccessToken = (state: RootState) =>
    state.tokenHandling?.accessToken ? state.tokenHandling.accessToken : 'no_token_available';
export const getIdToken = (state: RootState) => state.tokenHandling.idToken;
