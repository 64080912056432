import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import TableViewToggles, { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { FormattedMessage } from 'react-intl';
import { TagCreationDialog } from '../../create/TagCreationDialog';
import { openTagCreationDialog } from '../../create/tagCreationDialogSlice';
import { useAppDispatch } from '../../../../../configuration/setup/hooks';
import { tagsTableViewTypeChanged } from './tagListToolbarSlice';

export const TagListToolbar = () => {
    const dispatch = useAppDispatch();

    const handleOpenTagCreationDialog = () => {
        dispatch(openTagCreationDialog());
    };

    const handleViewTypeChange = (viewType: TableViewTogglesViewType) => dispatch(tagsTableViewTypeChanged(viewType));

    return (
        <div id="TagsTableToolbar">
            <TableToolbar>
                <div className="table-toolbar-container">
                    <div className="table-toolbar-group-left">
                        <div className="table-toolbar-column">
                            <button
                                className="btn btn-primary"
                                type="button"
                                id="addTag"
                                data-cy="add-tag"
                                onClick={handleOpenTagCreationDialog}
                            >
                                <span className="rioglyph rioglyph-plus" />
                                <FormattedMessage id={'tags.tagListToolbar.addButton'} />
                            </button>
                        </div>
                        <TagCreationDialog />
                    </div>
                    <div className="table-toolbar-group-right">
                        <div className="table-toolbar-column">
                            <div className="btn-toolbar">
                                <TableViewToggles onViewTypeChange={handleViewTypeChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </TableToolbar>
        </div>
    );
};
