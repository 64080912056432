import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { IntlShape } from 'react-intl';
import { Tag, TagType } from '../types';
import { v4 as uuidV4 } from 'uuid';
import { getAccessToken } from '../../../../configuration/tokenHandling/tokenHandlingSlice';
import { config } from '../../../../config';
import { ERROR_CODE_GENERIC, ERROR_CODE_NAME_ALREADY_IN_USE } from '../details/TagListDetails';
import { nonVanishingErrorNotification } from '../../ClickableNotifications';
import { RootState } from '../../../../configuration/setup/store';

export const createTag = createAsyncThunk(
    'tagCreationDialog/createTag',
    async (
        props: {
            accountId: string;
            tagName: string;
            navigate: NavigateFunction;
            intl: IntlShape;
        },
        thunkAPI
    ) => {
        const { accountId, tagName, navigate, intl } = props;

        const accessToken = getAccessToken(thunkAPI.getState() as RootState);

        const tag: Tag = {
            id: uuidV4(),
            type: TagType.USER,
            name: tagName,
            account_id: accountId,
        };

        const url = new URL(`${config.backend.tagService}/tags/${tag.id}`);

        const response = await fetch(url.toString(), {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'If-None-Match': '*',
            },
            body: JSON.stringify(tag),
        });
        if (response.ok) {
            navigate({ pathname: `/tags/${tag.id}` });
            return thunkAPI.fulfillWithValue(tag);
        } else {
            let errorCode = null;

            if ([400, 404, 409].includes(response.status)) {
                const body: { title: string; status: number; detail: string } = await response.json();

                if (body.detail) {
                    const errorCodeRegex = new RegExp('^\\[(.+)]:.*$');
                    const matches = errorCodeRegex.exec(body.detail);
                    errorCode = matches && matches.length === 2 ? matches[1] : null;
                }
            } else if ([401, 403].includes(response.status)) {
                errorCode = 'UNAUTHORIZED';
            }

            const messageId =
                errorCode === ERROR_CODE_NAME_ALREADY_IN_USE
                    ? 'tags.tagCreationDialog.createFailure.invalidTagName'
                    : 'tags.tagCreationDialog.createFailure';
            nonVanishingErrorNotification(
                intl.formatMessage({ id: messageId }, { tagName: tagName }),
                errorCode ?? ERROR_CODE_GENERIC
            );

            return thunkAPI.rejectWithValue({ errorCode });
        }
    }
);
